<template>
  <div class="menu-relative" id="slidebar">
    <div class="Menu logo-close">
      <span class="Menu-logo mb-4">
        <router-link to="/home" class="after-element"
          >
          <!-- <Logo class="ml-logo mb-4 mt-4" /> -->
          <img v-if="currentLogo && !logoOpen" :src="currentLogo" alt="logo" class="ml-logo mb-4 mt-4">
          <img v-else-if="currentLogo === null && !logoOpen" src="@/assets/icons/logo-fake.svg" alt="logo" class="ml-logo mb-4 mt-4">
          
          <img v-if="currentLogoMark && logoOpen" :src="currentLogoMark" alt="logo" class="ml-logo logo-big mb-4 mt-4">
          <img v-else-if="currentLogo && currentLogoMark === null && logoOpen" :src="currentLogo" alt="logo" class="ml-logo mb-4 mt-4">
          <img v-else-if="currentLogo === null && currentLogoMark === null && logoOpen" src="@/assets/icons/logo-fake.svg" alt="logo" class="ml-logo mb-4 mt-4">
          </router-link>
      </span>
      <Menu />
    </div>
  </div>
</template>
<script>
import Menu from "./Menu";
import Logo from "@/components/base/Logo.vue";

export default {
  data() {
    return {
      srcLogo: "",
      logoOpen: false,
    };
  },
  components: {
    Menu,
    Logo
  },
  computed:{
    currentLogoMark(){
      if (this.$store.getters.metasSite) {
        return this.$store.getters.metasSite.logomarca;
      } else return null;
    },
    currentLogo(){
      if (this.$store.getters.metasSite) {
        return this.$store.getters.metasSite.logo;
      } else return null;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
    minimizeSidebar() {
      this.$store.commit("changeSidebarState");
    },
    logo() {
      this.srcLogo = require("@/assets/logo-collapsed.png");
      const menu = document.querySelector(".Menu");

      menu.addEventListener("mouseenter", () => {
        this.logoOpen = true;
        menu.classList.remove("logo-close");
      });
      menu.addEventListener("mouseleave", () => {
        this.logoOpen = false;
        menu.classList.add("logo-close");
      });
    },
  },
  mounted() {
    this.logo();
  },
};
</script>

<style scoped>
.menu-relative {
  position: relative;
  width: 100px;
  /* height: 100vh; */
}
.Menu {
  padding: 20px 40px;
  background: var(--backgroundcolor);
  border: 1px solid var(--bordercolor);
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: none;
  margin: -1px;
  max-width: 100px;
  height: 101vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.3s;
  z-index: 1000 !important;
  overflow: hidden;
}
.Menu:hover {
  max-width: 300px;
}

.Menu-logo{
  height: 60px;
}

.logo-big {
  width: 115px !important;
}

.Menu-logo img {
  margin: auto -10px;
  width: 45px;
  height: auto;
  /* transition: 0.3s; */
  max-width: fit-content;
}

.ml-logo{
  margin-left: -15px;
}
</style>